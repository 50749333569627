import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import type { Observable } from 'rxjs';
import {
  map,
  of,
  switchMap,
} from 'rxjs';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { IncentiveProgramProgressService } from '../incentive-program-progress.service';
import type { Program, ProgramProgress } from '../types';

interface ViewModel {
  isLoading: boolean; // Loading indicator
  program?: Program; // The incentive program
  progress?: ProgramProgress; // User's program progress
  totalEntries: number;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    NgOptimizedImage,
    RouterModule,
  ],
  selector: 'lux-internal-incentive-program-widget',
  standalone: true,
  styleUrls: [ './internal-incentive-program-widget.component.scss' ],
  templateUrl: './internal-incentive-program-widget.component.html',
})
export class InternalIncentiveProgramWidgetComponent {
  public readonly vm$: Observable<ViewModel>;

  constructor(private readonly incentiveProgramProgressService: IncentiveProgramProgressService) {
    this.vm$ = this.incentiveProgramProgressService.getInternalProgram().pipe(
      switchMap((program: Program | undefined): Observable<ViewModel> => {
        if (!program) {
          // No program found, return empty state
          return of({
            isLoading: false,
            program: undefined,
            progress: undefined,
            totalEntries: 0,
          });
        }

        // Since we have a program, get its progress and map to ViewModel
        return this.incentiveProgramProgressService.getProgramProgress(program.id).pipe(
          map((progress: ProgramProgress | undefined): ViewModel => {
            let totalEntries = 0;
            if (progress?.goalProgress) {
              for (const goalProgress of progress.goalProgress) {
                totalEntries += goalProgress.progress.currentValue;
              }
            }
            return {
              isLoading: false,
              program,
              progress: progress ?? undefined,
              totalEntries,
            };
          }),
        );
      }),
      // Emit loading state initially
      map((viewModel: ViewModel): ViewModel => ({
        ...viewModel,
        isLoading: !viewModel.program,
      })),
    );
  }
}
