// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  display: flex;
  flex-wrap: wrap-reverse;
  gap: 0.75rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  background-color: #2a5a61;
  padding: 1rem;
  color: #f1f3f3;
}
@media (min-width: 768px) {
  .wrapper {
    padding: 1rem 2rem;
  }
}

.summary {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.45rem;
  margin: 0.5rem 0;
}

.subtitle {
  opacity: 0.8;
  margin-left: 0.25rem;
  font-weight: 700;
}

.box-chart {
  display: block;
  flex: 1;
  border-radius: 0.5rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  background: #082732;
  padding: 1rem 2rem 1rem 0;
  max-width: 100%;
}

.net-worth {
  border-top: 1px solid #5e6b6f;
  padding-top: 8px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
