import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { CheckupItem, CheckupItemCategory } from './checkup-items';

@Injectable({ providedIn: 'root' })
export abstract class CheckupDataService {
  public abstract readonly categories$: Observable<CheckupItemCategory[]>;
  public abstract readonly checkupItems$: Observable<CheckupItem[]>;

  public abstract getCategoryItemsByTier(tier: number): Observable<CheckupItem[]>;

  public abstract getCheckupItemByKey(key: string): Observable<CheckupItem>;
}
