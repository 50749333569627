// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  font-size: 1.125rem;
}

@media (min-width: 992px) {
  .status-done lux-confetti {
    display: block;
    position: relative;
  }
  .status-done lux-confetti::before {
    display: block;
    position: absolute;
    top: -140px;
    left: 0;
    background-image: url("https://static.learnlux.com/www-2/background/dot-v1.0.jpg");
    background-position: center;
    background-size: 13px;
    width: 400px;
    height: 140px;
    content: "";
  }
}
.status-done .material-symbols-rounded {
  position: relative;
  opacity: 0;
  z-index: 1;
  border: 1px solid #9faaae;
  border-radius: 50%;
  animation-name: bounce-in;
  animation-duration: 450ms;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  color: #29a59a;
  font-size: 11.25rem;
}
.status-done .display-1 {
  font-size: 2.5rem;
}
.status-done p {
  margin: 1rem auto;
  max-width: 30rem;
}

.status-done,
.status-dismissed {
  border-radius: 0.5rem;
  padding: 1.25rem 0.875rem;
  width: 100%;
  text-align: center;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.inprogress-actions {
  margin-top: 2rem;
  border-radius: 0.5rem;
  background-color: rgba(41, 165, 154, 0.1);
  padding: 1.5rem 2.5rem;
  width: 100%;
  text-align: center;
}
.inprogress-actions .btn:hover .material-symbols-rounded {
  animation-name: wiggle;
  animation-duration: 1000ms;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

@media (max-width: 991.98px) {
  .inprogress-actions {
    padding: 1rem;
  }
  .btn-dismiss {
    --bs-btn-padding-x: .25rem;
  }
}
.suggested-lessons {
  margin-top: 1rem;
  border-radius: 0.5rem;
  padding: 1.25rem 1.875rem;
}
.suggested-lessons p {
  text-transform: uppercase;
  letter-spacing: 0.2px;
  color: #5e6b6f;
  font-size: 0.75rem;
}

@keyframes wiggle {
  0% {
    transform: rotate(10deg);
  }
  25% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(20deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes bounce-in {
  0% {
    transform: scale(0.3) translate3d(0, 0, 0);
    opacity: 0;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.9;
  }
  80% {
    transform: scale(0.89);
    opacity: 1;
  }
  100% {
    transform: scale(1) translate3d(0, 0, 0);
    opacity: 1;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
