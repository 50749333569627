// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.incentive-widget-wrapper {
  position: relative;
  margin-bottom: 1rem;
  border: 1px solid #d6dbdc;
  border-radius: 0.375rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  padding-bottom: 0.75rem;
  overflow: hidden;
  text-align: center;
}
.incentive-widget-wrapper a:hover {
  text-decoration: none;
}

.widget-top-border {
  display: block;
  padding: 0;
  width: 100%;
  height: auto;
  line-height: 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0;
  padding-right: 1rem;
  padding-left: 1rem;
}

.info {
  margin: 0 1rem;
  padding-top: 1rem;
  color: #475053;
  font-size: 1rem;
}

.earnings {
  margin-bottom: 0;
  padding: 0.5rem 0 0 1rem;
  text-align: left;
  color: #b37700;
  font-size: 1rem;
  font-weight: 600;
}
.earnings.complete {
  font-weight: 600;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
