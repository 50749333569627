import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import type { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { map, ReplaySubject, switchMap } from 'rxjs';
import type { Observable } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { CheckupService } from '@app/checkup/checkup.service';
import type { CheckupItem } from '@app/checkup/checkup.service';
import { LoggerService } from '@app/logger/logger.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import { TranslationModule } from '@app/translation/translation.module';
import type { UserSuggestion } from '@app/users/user-suggestions.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { EVENTS, UserTrackingService } from '@app/users/user-tracking.service';

import { SuggestionPreviewModalComponent } from './preview-modal/preview-modal.component';

export interface ViewModel {
  checkupItem: CheckupItem;
  suggestion: UserSuggestion;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    NgOptimizedImage,
    TranslationModule,
  ],
  selector: 'lux-suggestion-preview',
  standalone: true,
  styleUrls: [ './suggestion-preview.component.scss' ],
  templateUrl: './suggestion-preview.component.html',
})
export class SuggestionPreviewComponent {
  public readonly vm$: Observable<ViewModel>;

  private readonly _suggestionSub$: ReplaySubject<UserSuggestion>;

  constructor(
    private readonly checkupService: CheckupService,
    private readonly logger: LoggerService,
    private readonly modalService: NgbModal,
    private readonly userTrackingService: UserTrackingService,
  ) {
    this._suggestionSub$ = new ReplaySubject<UserSuggestion>(1);

    this.vm$ = this._suggestionSub$.pipe(
      switchMap(
        (suggestion: UserSuggestion): Observable<ViewModel> =>
          // getCheckupItemByKey will throw an error is the referenceKey doesn't map to a checkupItem
          this.checkupService.getCheckupItemByKey(suggestion.referenceKey).pipe(
            map((checkupItem: CheckupItem): ViewModel => {
              return { checkupItem, suggestion };
            }),
          ),
      ),
    );
  }

  @Input({ required: true }) public set suggestion(val: UserSuggestion) {
    this._suggestionSub$.next(val);
  }

  /** Open the suggestion modal passing in the suggestion id for it to subscribe to the UserSuggestion. */
  public open(suggestion: UserSuggestion): NgbModalRef {
    this.logger.track(`SuggestionPreviewComponent#open#${suggestion.id ?? 'Missing ID'}`);

    this.userTrackingService.trackEvent(EVENTS.suggestionsSuggestionPreviewModalOpened, {
      id: suggestion.id,
      referenceKey: suggestion.referenceKey,
      type: suggestion.type,
    });

    const modalRef = this.modalService.open(SuggestionPreviewModalComponent, { scrollable: true, size: 'lg' });
    // Because the NgbModalRef is a dynamitcally created component we need to have the modal subscribe
    // to the suggestion so it gets updates.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.suggestionId = suggestion.id;
    return modalRef;
  }
}
