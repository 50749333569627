/**
 * A service that handles email verification actions.
 */
import { Injectable } from '@angular/core';
import {
  from,
  map,
  switchMap,
} from 'rxjs';
import type { Observable } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AngularFireAuthService, FirebaseActionCodeInfo } from '../angular-fire-shims/angular-fire-auth.service';

@Injectable({ providedIn: 'root' })
export class EmailVerificationService {
  constructor(
    private readonly afAuth: AngularFireAuthService,
  ) {}

  /**
   * Applies an email verification action code
   *
   * @param actionCode - The action code to apply.
   * @returns An observable that emits the FirebaseActionCodeInfo once the action code has been applied.
   */
  public handleChangeEmailCode(actionCode: string): Observable<FirebaseActionCodeInfo> {
    return from(this.afAuth.checkActionCode(actionCode)).pipe(
      switchMap(
        (codeInfo: FirebaseActionCodeInfo): Observable<FirebaseActionCodeInfo> => from(this.afAuth.applyActionCode(actionCode)).pipe(
          map((): FirebaseActionCodeInfo => codeInfo),
        ),
      ),
    );
  }
}
