import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { CheckupDataService } from './checkup-data.service';
import type {
  CheckupItem,
  CheckupItemCategory,
} from './checkup-items';

export type { CheckupItem, CheckupItemCategory };

@Injectable({ providedIn: 'root' })
export class CheckupService {
  /** A list of checkup item categories sorted by tier. */
  public readonly categories$: Observable<CheckupItemCategory[]>;

  /** A list of all Checkup Items sorted by tier and order with their lessons loaded. */
  public readonly checkupItems$: Observable<CheckupItem[]>;

  constructor(private readonly checkupDataService: CheckupDataService) {
    this.categories$ = this.checkupDataService.categories$;
    this.checkupItems$ = this.checkupDataService.checkupItems$;
  }

  /** Get a list of Checkup Items (including lessons) within a particular Category tier. */
  public getCategoryItemsByTier(tier: number): Observable<CheckupItem[]> {
    return this.checkupDataService.getCategoryItemsByTier(tier);
  }

  /** Get a specific checkup item by key. */
  public getCheckupItemByKey(key: string): Observable<CheckupItem> {
    return this.checkupDataService.getCheckupItemByKey(key);
  }
}
