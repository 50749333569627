import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AngularFireFunctionsService } from '../angular-fire-shims/angular-fire-functions.service';
import type { CheckupDataService } from './checkup-data.service';
import type { CheckupItem, CheckupItemCategory, CheckupItemStrapi } from './checkup-items';

@Injectable({ providedIn: 'root' })
export class CMSCheckupDataService implements CheckupDataService {
  public readonly categories$: Observable<CheckupItemCategory[]>;
  public readonly checkupItems$: Observable<CheckupItem[]>;

  constructor(
    private readonly afFns: AngularFireFunctionsService,
  ) {
    this.categories$ = this.getCategoryItemsByTier().pipe(
      shareReplay({ bufferSize: 1, refCount: false }),
    );

    this.checkupItems$ = this.getCheckupItems().pipe(
      map(this._removeIdFromStrapiOptions.bind(this)),
      shareReplay({ bufferSize: 1, refCount: false }),
    );
  }

  public getCategoryItemsByTier(): Observable<CheckupItem[]> {
    const callable = this.afFns.httpsCallable<string | undefined, CheckupItem[]>('getCheckupOrderedByTier');
    return callable(undefined);
  }

  public getCheckupItemByKey(key: string): Observable<CheckupItem> {
    const callable = this.afFns.httpsCallable<{ key: string }, CheckupItem>('getCheckupItemByKey');
    return callable({ key });
  }

  public getCheckupItems(): Observable<CheckupItem[]> {
    const callable = this.afFns.httpsCallable<string | undefined, CheckupItem[]>('getCheckupItems');
    return callable(undefined);
  }

  private _removeIdFromStrapiOptions(checkupItems: CheckupItemStrapi[]): CheckupItem[] {
    return checkupItems.map((item: CheckupItemStrapi): CheckupItem => {
      const { id: _id, ...optionsWithoutId } = item.options;
      return {
        ...item,
        options: optionsWithoutId,
      };
    });
  }
}
