import type { FirestoreTimestamp } from '@app/angular-fire-shims/angular-firestore.service';

interface CheckupItemCategoryBase {
  readonly date: Date | FirestoreTimestamp;
  readonly description: string;
  readonly tier: number;
  readonly title: string;
  readonly translationKeys?: {
    readonly description: string;
    readonly title: string;
  };
}

export interface CheckupItemCategory extends CheckupItemCategoryBase {
  readonly date: Date;
}

export interface CheckupItemCategoryFirestore extends CheckupItemCategoryBase {
  readonly date: FirestoreTimestamp;
}

/** Valid range for Checkup Tiers is 1 through 4 inclusive. */
export const TIER = {
  max: 4,
  min: 1,
} as const;

export type CheckupItemOptionsKeys = 'done' | 'inprogress' | 'opportunity' | 'skip';

type CheckupItemOptions = Partial<{
  [key in CheckupItemOptionsKeys]: string;
}>;

export type CheckupOptionLabels = Record<CheckupItemOptionsKeys, string>;

export const CHECKUP_OPTION_LABELS: CheckupOptionLabels = {
  done: 'Done',
  inprogress: 'In Progress',
  opportunity: 'Opportunity',
  skip: 'Skip',
} as const;

interface CheckupItemBase {
  readonly date: Date | FirestoreTimestamp;
  readonly description: string;
  readonly imgUrl: string;
  readonly key: string;
  readonly lessons: string[];
  readonly options: CheckupItemOptions;
  readonly order: number;
  readonly suggestion: string;
  readonly title: string;
  readonly translationKeys?: {
    // Using an Index Signature for this makes the template type checker in `ng build` happier.
    // Arbitrary keys are not desired.
    readonly [key: string]: string | undefined;
    readonly description: string; // eslint-disable-line perfectionist/sort-object-types
    readonly done?: string;
    readonly inprogress?: string;
    readonly opportunity?: string;
    readonly skip?: string;
    readonly suggestion: string;
    readonly title: string;
  };
}

export interface CheckupItem extends CheckupItemBase {
  readonly date: Date;
  readonly tier: number;
}

interface CheckupItemOptionsStrapi {
  readonly id?: number;
  readonly done?: string;
  readonly inprogress?: string;
  readonly opportunity?: string;
  readonly skip?: string;
}

export interface CheckupItemStrapi extends CheckupItemBase {
  readonly date: Date;
  readonly options: CheckupItemOptionsStrapi;
  readonly tier: number;
}

export interface CheckupItemFirestore extends CheckupItemBase {
  readonly date: FirestoreTimestamp;
}
