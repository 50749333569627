/**
 * This is _Angular's_ Application Configuration file. Nor our AppConfig that downloads JSON files
 * from the assets/ directory before the application bootstraps in main.ts.
 */
import { provideHttpClient } from '@angular/common/http';
import {
  DEFAULT_CURRENCY_CODE,
  importProvidersFrom,
  inject,
  LOCALE_ID,
} from '@angular/core';
import type { ApplicationConfig } from '@angular/core';
import {
  initializeAnalytics,
  provideAnalytics,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/analytics';
import type { Analytics as FirebaseAnalytics, GtagConfigParams } from '@angular/fire/analytics';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import type { FirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import type { Auth as FirebaseAuth } from '@angular/fire/auth';
import { initializeFirestore, provideFirestore } from '@angular/fire/firestore';
import type { Firestore as FirebaseFirestore, FirestoreSettings } from '@angular/fire/firestore';
import { connectFunctionsEmulator, getFunctions, provideFunctions } from '@angular/fire/functions';
import type { Functions as FirebaseFunctions } from '@angular/fire/functions';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import type { FirebasePerformance } from '@angular/fire/performance';
import { HAMMER_LOADER, HammerModule } from '@angular/platform-browser';
import {
  provideRouter,
  TitleStrategy,
  withInMemoryScrolling,
  withRouterConfig,
} from '@angular/router';

import { environment } from '../environments/environment';
import { APP_CONFIG } from './app-config/app-config';
import type { AppConfig } from './app-config/app-config';
import { routes } from './app.routes';
import { CHECKUP_DATA_SERVICE_PROVIDER } from './checkup/checkup-data.service.provider';
import { LESSONS_DATA_SERVICE_PROVIDER } from './lessons/lessons-data.service.provider';
import { BreadcrumbTitleService } from './utilities/breadcrumb-title.service';
import { ERROR_HANDLER_PROVIDERS } from './utilities/error-handler';
import { GESTURE_PROVIDERS } from './utilities/gesture-config';

const analyticsConfig: GtagConfigParams = {
  /* eslint-disable @typescript-eslint/naming-convention */
  allow_ad_personalization_signals: false,
  allow_google_signals: false,
  anonymize_ip: true,
  /* eslint-enable @typescript-eslint/naming-convention */
} as const;

const firestoreSettings: FirestoreSettings = {
  experimentalAutoDetectLongPolling: true,
  ignoreUndefinedProperties: true,
} as const;

export const applicationConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      provideFirebaseApp(
        (): FirebaseApp => {
          const appConfig = inject(APP_CONFIG);
          return initializeApp(appConfig.firebase);
        },
        [ APP_CONFIG ],
      ),
      provideAuth((): FirebaseAuth => getAuth()),
      provideAnalytics((): FirebaseAnalytics => initializeAnalytics(getApp(), { config: analyticsConfig })),
      provideFirestore((): FirebaseFirestore => initializeFirestore(getApp(), firestoreSettings)),
      provideFunctions((): FirebaseFunctions => {
        const functions = getFunctions();
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (environment.emulateFns) {
          connectFunctionsEmulator(functions, 'localhost', 5001);
        }
        return functions;
      }),
      providePerformance((): FirebasePerformance => getPerformance()),
    ),
    // Firebase Analytics
    ScreenTrackingService,
    UserTrackingService,

    provideHttpClient(), // import HttpClientModule after BrowserModule.
    importProvidersFrom(HammerModule), // Hammerjs gesture/touch events
    GESTURE_PROVIDERS, // Hammerjs config
    // tsconfig allowSyntheticDefaultImports gets confused about this import... sigh.
    { provide: HAMMER_LOADER, useValue: async (): Promise<HammerStatic> => import('hammerjs') as unknown as Promise<HammerStatic> },

    CHECKUP_DATA_SERVICE_PROVIDER,
    ERROR_HANDLER_PROVIDERS,
    LESSONS_DATA_SERVICE_PROVIDER,
    { provide: DEFAULT_CURRENCY_CODE, useFactory: (appConfig: AppConfig): string => appConfig.currency, deps: [ APP_CONFIG ] },
    { provide: LOCALE_ID, useFactory: (appConfig: AppConfig): string => appConfig.locale, deps: [ APP_CONFIG ] },

    // App Routes
    provideRouter(
      routes,
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled',
      }),
      withRouterConfig({ onSameUrlNavigation: 'reload' }),
    ),
    { provide: TitleStrategy, useClass: BreadcrumbTitleService },
  ],
};
