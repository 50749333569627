import type { FactoryProvider } from '@angular/core';

import { AngularFireFunctionsService } from '@app/angular-fire-shims/angular-fire-functions.service';
import { AngularFirestoreService } from '@app/angular-fire-shims/angular-firestore.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { APP_CONFIG, AppConfig } from '@app/app-config/app-config';

import { CMSCheckupDataService } from './checkup-cms-data.service';
import { CheckupDataService } from './checkup-data.service';
import { FirestoreCheckupDataService } from './checkup-firestore-data.service';

const checkupDataServiceFactory = (
  appConfig: AppConfig,
  afStore: AngularFirestoreService,
  afFns: AngularFireFunctionsService,
): CheckupDataService =>
  appConfig.useCMSCheckup
    ? new CMSCheckupDataService(afFns)
    : new FirestoreCheckupDataService(afStore);

const checkupDataServiceProvider: FactoryProvider = {
  provide: CheckupDataService,
  useFactory: checkupDataServiceFactory,
  deps: [ APP_CONFIG, AngularFirestoreService, AngularFireFunctionsService ],
};

export const CHECKUP_DATA_SERVICE_PROVIDER = [ checkupDataServiceProvider ];
