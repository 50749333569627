import { Injectable } from '@angular/core';
import {
  catchError,
  map,
  of,
  switchMap,
} from 'rxjs';
import type { Observable } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AngularFirestoreService } from '@app/angular-fire-shims/angular-firestore.service';
import { AuthService } from '@app/auth/auth.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import type { FirebaseUser } from '../angular-fire-shims/angular-fire-auth.service';
import type {
  Goal,
  Program,
  ProgramProgress,
} from './types';

@Injectable({ providedIn: 'root' })
export class IncentiveProgramProgressService {
  constructor(
    private readonly afStore: AngularFirestoreService, // Updated to use AngularFirestoreService
    private readonly authService: AuthService,
  ) {}

  /**
   * Fetch the first external program from the programs collection with type 'incentive' and visible 'true' without internal 'true'.
   */
  public getFirstProgram(): Observable<Program | undefined> {
    const programsRef = this.afStore.collection<Program>('programs');
    const programsQuery = this.afStore.query(
      programsRef,
      this.afStore.where('type', '==', 'incentive'),
      this.afStore.where('visible', '==', true),
      this.afStore.where('internal', '==', false),
      this.afStore.limit(1),
    );
    return this.afStore.collectionData(programsQuery).pipe(
      map((programs: Program[]): Program | undefined => programs.length > 0 ? programs[0] : undefined),
      catchError((): Observable<Program | undefined> => of(undefined)), // Swallow errors and return undefined
    );
  }

  /**
   * Fetch all goals for a given program ID.
   */
  public getGoalsForProgram(programId: string): Observable<Goal[]> {
    const goalsRef = this.afStore.collection<Goal>('program-goals');
    const goalsQuery = this.afStore.query(goalsRef, this.afStore.where('programId', '==', programId));

    return this.afStore.collectionData(goalsQuery).pipe(
      map((goals: Goal[]): Goal[] => goals.map((goal: Goal): Goal => ({ ...goal, id: goal.id }))),
      catchError((): Observable<Goal[]> => of([])), // Swallow errors and return an empty array
    );
  }

  /**
   * Fetch the first internal program from the programs collection with type 'incentive' and visible 'true'.
   */
  public getInternalProgram(): Observable<Program | undefined> {
    const programsRef = this.afStore.collection<Program>('programs');
    const programsQuery = this.afStore.query(
      programsRef,
      this.afStore.where('type', '==', 'incentive'),
      this.afStore.where('visible', '==', true),
      this.afStore.where('internal', '==', true),
      this.afStore.limit(1),
    );
    return this.afStore.collectionData(programsQuery).pipe(
      map((programs: Program[]): Program | undefined => programs.length > 0 ? programs[0] : undefined),
      catchError((): Observable<Program | undefined> => of(undefined)), // Swallow errors and return undefined
    );
  }

  /**
   * Fetch the active program for the current date.
   */
  public getProgramById(programId: string): Observable<Program | undefined> {
    const programDoc = this.afStore.doc<Program>(`programs/${programId}`);
    return this.afStore.docData(programDoc).pipe(
      map((program: Program | undefined): Program | undefined => program ? { ...program, id: programId } : undefined),
      catchError((): Observable<Program | undefined> => of(undefined)), // Swallow errors and return undefined
    );
  }

  /**
   * Fetch the program progress for the current user and specified program.
   */
  public getProgramProgress(programId: string): Observable<ProgramProgress | undefined> {
    return this.authService.currentUser$.pipe(
      switchMap((user: FirebaseUser | null): Observable<ProgramProgress | undefined> => {
        if (!user) {
          return of(undefined);
        }
        const progressDoc = this.afStore.doc<ProgramProgress>(`program-progress/${user.uid}_${programId}`);
        return this.afStore.docData(progressDoc).pipe(
          catchError((): Observable<ProgramProgress | undefined> => of(undefined)), // Swallow errors
        );
      }),
      map((progress: ProgramProgress | undefined): ProgramProgress | undefined => progress ?? undefined),
      catchError((): Observable<ProgramProgress | undefined> => of(undefined)), // Swallow any additional errors
    );
  }
}
