import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { map, of } from 'rxjs';
import type { Observable } from 'rxjs';

import type { FirebaseUser } from '@app/angular-fire-shims/angular-fire-auth.service';
import type { FormUserData } from '@app/users/user';
import { UserDataService } from '@app/users/user-data.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports

import type { ProviderLogin } from './provider-login.service';

@Injectable({ providedIn: 'root' })
export class ProviderDataService {
  constructor(
    private readonly userService: UserDataService,
  ) {}

  public handleProviderData(result: ProviderLogin): Observable<ProviderLogin> {
    if (result.isNewUser && result.profile) {
      // Extract all profile data including email
      const userData: FormUserData = this._extractProfileData(result.profile, result.user);

      // Only update if we have any data to save
      if (Object.keys(userData).length > 0) {
        return this.userService.updateUserData(userData).pipe(
          map((): ProviderLogin => result),
        );
      }
    }
    return of(result);
  }

  /**
   * Extracts user data from the provider profile.
   */
  private _extractProfileData(profile: Record<string, string>, user?: FirebaseUser): FormUserData {
    const userData: FormUserData = {};

    for (const [ key, value ] of Object.entries(profile)) {
      // Do not import falsey values.
      if (value) {
        const lKey = this._normalizeKey(key);

        if (lKey === 'companyid') {
          // For example, 'companyid' might represent the employer name
          userData.employername = value;
        }
        if (lKey === 'eid' || lKey === 'employeeid' || lKey === 'uniqueid') {
          // e-id is the employee id for Fifth Third's SAML, employeeid for Plenty, UniqueId is for Aarons.
          userData.employeeId = value;
        }
        if (lKey === 'firstname' || lKey === 'givenname') {
          // TypeScript is happier using explicit property names instead of userData[key.toLowerCase()]
          userData.firstname = value;
        }
        if (lKey === 'lastname' || lKey === 'surname') {
          // TypeScript is happier using explicit property names instead of userData[key.toLowerCase()]
          userData.lastname = value;
        }
        if ((lKey === 'email' || lKey === 'emailaddress') && this._isValidEmail(value)) {
          userData.email = value;
        }
      }
    }

    // If no valid email was found in the profile, fallback to authenticated user's email
    if ((!userData.email || !this._isValidEmail(userData.email)) && user?.email && this._isValidEmail(user.email)) {
      userData.email = user.email;
    }

    return userData;
  }

  /**
   * Validates email format using Angular's built-in Validators.email.
   */
  private _isValidEmail(email: string): boolean {
    return !Validators.email(new FormControl(email));
  }

  /**
   * Since each SSO can use differnet field names, we normalize the field names to lower case
   * without any symbols (non-alphanumeric).
   * In the case of Azure we have namespaced names, which might actually be confusing if there are duplicates.
   * But for now just take the last part of the URL path as the key. If there are duplicates then we
   * don't handle that for now.
   */
  private _normalizeKey(key: string): string {
    return key
      .slice(Math.max(0, key.lastIndexOf('/') + 1))
      .toLowerCase()
      .replaceAll(/[^a-z0-9]/gu, '');
  }
}
