/**
 * For easier unit testing this provider wraps the environment object with an injector so we can modify
 * the settings in unit tests without changing a global object.
 */
import { InjectionToken } from '@angular/core';

import { environment } from '@env/environment';

// This needs to be kept up to date with environment.
export interface Environment {
  readonly emulateFns: boolean;
  readonly environment: string;
  readonly production: boolean;
  readonly transifexToken: string;
}

export const ENV = new InjectionToken<Environment>(
  'env',
  {
    factory: (): Environment => environment,
    providedIn: 'root',
  },
);
