import { ErrorHandler, Inject, Injectable } from '@angular/core';
import type { Provider } from '@angular/core';
import SentryFullStory from '@sentry/fullstory';
import type { FullStoryClient } from '@sentry/fullstory/dist/types';
import { CaptureConsole, ExtraErrorData } from '@sentry/integrations';

// Need to enable resolveJsonModule in tsconfig.json to make this work.
import VERSIONINFO from '@app/../version.json'; // eslint-disable-line perfectionist/sort-imports

import { APP_CONFIG } from '@app/app-config/app-config';
import type { AppConfig } from '@app/app-config/app-config';

import { ENV } from '../env.provider';
import type { Environment } from '../env.provider';
import { SENTRY } from '../sentry.provider';
import type { SentryType } from '../sentry.provider';
import { SessionStorageService } from '../storage/session-storage.service';
import { WINDOW } from '../window.provider';

// https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
type AngularWrappedError = Error & { ngOriginalError?: Error };

// https://github.com/getsentry/sentry-javascript/blob/master/packages/angular/src/errorhandler.ts
@Injectable({ providedIn: 'root' })
class SentryErrorHandler implements ErrorHandler {
  // https://medium.com/@kamrankhatti/angular-lazy-routes-loading-chunk-failed-42b16c22a377
  private readonly _chunkFailedMessage: RegExp = /Loading chunk \d+ failed/iu;

  constructor(
    @Inject(ENV) private readonly env: Environment,
    @Inject(SENTRY) private readonly sentry: SentryType,
    private readonly storage: SessionStorageService,
    @Inject(WINDOW) private readonly window: Window & { FS: FullStoryClient }, // eslint-disable-line @typescript-eslint/naming-convention
    @Inject(APP_CONFIG) private readonly appConfig: AppConfig,
  ) {
    const sentryPrdDsn = 'https://8b5042a14e504dcd87740d4200fd6a24@sentry.io/1357186';
    const sentryStgDsn = 'https://ff3f53ccf6ce609397f3ad6485c6aa3f@o216502.ingest.us.sentry.io/4508999753072640';
    const { environment } = this.env;
    const dsn = environment === 'production' ? sentryPrdDsn : sentryStgDsn;

    this.sentry.init({
      dsn,
      environment,
      integrations: [
        // Registers and configures the Tracing integration, which automatically instruments your
        // application to monitor its performance, including custom Angular routing instrumentation.
        new this.sentry.BrowserTracing({
          routingInstrumentation: this.sentry.routingInstrumentation,
          tracePropagationTargets: [], // We don't want Sentry API request tracing so hopefully this turns that off.
        }),
        new CaptureConsole({ levels: [ 'error' ] }),
        new ExtraErrorData(),
        new SentryFullStory('learnlux-7u', { client: this.window.FS }),
      ],
      release: `${VERSIONINFO.version}-${VERSIONINFO.hash.slice(0, 7)}`,
      tracesSampleRate: 0.1,
    });
  }

  public handleError(error: AngularWrappedError): void {
    const nowTs = Date.now();
    const { locale, organization } = this.appConfig;
    this.sentry.setTags({ locale, organization });
    // Only reload once every 10 seconds for the chunk failed error
    const reloadTs = Number(this.storage.get('lux-chunk-loading-reload') ?? '0') + 10_000;
    if (reloadTs < nowTs && this._chunkFailedMessage.test(error.message)) {
      console.error('Attempting reload to handle chunk loading error:', error, 'reloadTs:', reloadTs, 'nowTs:', nowTs);
      // I'm worried about a refresh loop here, so record when we last reloaded to try and prevent it happening multiple times.
      this.storage.set('lux-chunk-loading-reload', `${nowTs}`);
      this.window.location.reload();
    } else if (this.env.production) {
      this.sentry.captureException(error.ngOriginalError ?? error);
    } else {
      console.error(error);
    }
  }
}

const deps = [ ENV, SENTRY, SessionStorageService, WINDOW, APP_CONFIG ];

export const ERROR_HANDLER_PROVIDERS: Provider[] = [ { provide: ErrorHandler, useClass: SentryErrorHandler, deps } ];
