import { InjectionToken } from '@angular/core';
import type { FirebaseOptions } from '@firebase/app-types';

// Configuration for how to calculate the employer's 401(k) match.
// More documentation in lessons-calculator.service.ts
export interface MatchConfig {
  // Number between 0-100. The maximum percentage of an employee's
  // salary that the employer will match into their 401(k).
  readonly max: number;

  // Number between 0-100. The minimum percentage of an employee's
  // salary that the employer will match into their 401(k).
  readonly min: number;

  // Number between 0-100. Percentage match that the employer
  // will contribute into an employee's 401(k).
  readonly percent: number;

  // The type of calculation used for this employer's match.
  // Includes: 'stepped', 'flat'
  readonly type: string;
}

export interface AppConfig {
  readonly clientLogo?: string;
  readonly currency: string;
  readonly customLuxLogo?: string;
  readonly firebase: FirebaseOptions;
  readonly hubspotScriptSrc?: string;
  readonly locale: string;
  readonly matchConfig?: MatchConfig;
  readonly organization: string;
  readonly useCMS?: boolean; // Currently, only Lessons
  readonly useCMSCheckup?: boolean;
}

export const APP_CONFIG = new InjectionToken<AppConfig>('app.config');
